@import "_variable";

html {
  font-size: 14px;
}

body {
  background-color: #ffffff;
  color: #000000;
  font-family: $jpFamily;
  line-height: 1.6;
}

a {
  display: inline-block;
  line-height: 1;
  transition: all .3s ease;

  &.telLink {
    color: $green;
    text-decoration: none;

    @media #{$mq-min-md} {
      pointer-events: none;
    }
  }
}

img {
  max-width: 100%;
}
