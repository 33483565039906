@import "_variable";

// -----------------------------------------------------------
// 電話番号
// -----------------------------------------------------------

.el-cta-tel {
  text-align: center;
  font-size: 18px;
  color: $dimGray;
  @media #{$mq-lg} {
    font-size: 15px;
  }
  @media #{$mq-md} {
    font-size: 12px;
  }

  &-num {
    position: relative;
    padding-left: 1.2em;
    color: $green;
    font-size: 200%;
    font-family: $enFamily;
    line-height: 1;
    @media #{$mq-md} {
      font-size: 150%;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: calc(50% - 3.2em);
      display: inline-block;
      width: 1em;
      height: 1em;
      background: url("../../images/icon/icon-freetel.svg") no-repeat top 50%
        left 50%;
      background-size: contain;
      content: "";
      transform: translate(-50%, -50%);
    }
  }

  &-subtxt {
    font-size: 83.33%;
  }
}

// -----------------------------------------------------------
// 折返し予約ボタン
// -----------------------------------------------------------

.el-cta-btn {
  position: relative;
  padding: 10px 20px;
  padding-left: 0.8em;
  padding-right: 2.1em;
  border: 1px solid $orange;
  border-radius: 50px;
  background-color: $orange;
  color: #ffffff;
  text-decoration: none;

  @media #{$mq-min-md} {
    font-size: 19px;
  }
  @media #{$mq-md} {
    font-size: 15px;
  }

  &:after {
    position: absolute;
    top: 50%;
    right: 10px;
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    background: url("../../images/icon/icon-cta-arrow.svg") no-repeat top 50%
      left 50%;
    background-size: contain;
    content: "";
    transform: translateY(-50%);
  }

  &:hover,
  &:active {
    background-color: $lightOrange;
  }
}

// -----------------------------------------------------------
// タイトル
// -----------------------------------------------------------

.el-ttl {
  font-weight: 600;
  font-size: 22px;

  @media #{$mq-min-md} {
    font-size: 38px;
  }
}

// -----------------------------------------------------------
// FAQ
// -----------------------------------------------------------

.el-faq {
  margin-bottom: 20px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 5px $gray;

  @media #{$mq-min-lg} {
    margin-bottom: 25px;
  }

  &-ttl {
    color: $orange;
    text-align: center;
    font-size: 27px;
    font-family: $enFamily;

    @media #{$mq-min-lg} {
      font-size: 34px;
    }
  }

  &-item {
    position: relative;
    display: flex;

    p {
      margin: 0;
      padding-left: 40px;

      @media #{$mq-min-lg} {
        padding-left: 45px;
      }
    }

    .el-faq-ttl {
      position: absolute;
      width: 1.2em;
      height: 1.2em;
    }
  }

  &-q {
    position: relative;
    padding: 20px 10px;

    @media #{$mq-min-lg} {
      padding: 25px;
    }

    &:hover {
      cursor: pointer;
    }

    &:after {
      position: absolute;
      top: 20px;
      right: 15px;
      display: inline-block;
      width: 22px;
      height: 22px;
      border-radius: 100px;
      background-color: $orange;
      color: #ffffff;
      content: "＋";
      text-align: center;
      font-size: 14px;
      line-height: 22px;

      @media #{$mq-min-lg} {
        top: 28px;
        width: 30px;
        height: 30px;
        font-size: 20px;
        line-height: 30px;
      }
    }

    .el-faq-ttl {
      top: 10px;

      @media #{$mq-min-lg} {
        top: 15px;
      }
    }

    p {
      padding-right: 32px;
      font-size: 16px;
      color: $dimGray;

      @media #{$mq-min-lg} {
        font-size: 23px;
      }
    }
  }

  &-q.is-active {
    &:after {
      content: "−";
      line-height: 20px;

      @media #{$mq-min-lg} {
        line-height: 26px;
      }
    }
  }

  &-a {
    padding: 0 10px 20px 10px;

    @media #{$mq-min-lg} {
      padding: 0 25px 25px;
    }

    @media #{$mq-min-lg} {
      font-size: 18px;
    }

    .el-faq-ttl {
      top: -10px;

      @media #{$mq-min-lg} {
        top: -15px;
      }
    }

    p {
      color: $dimGray;
    }
  }

  &-subtxt {
    display: inline-block;
    margin-left: 1em;
  }
}

// -----------------------------------------------------------
// セルフチェック
// -----------------------------------------------------------

.el-selfcheck-item {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px $gray;

  @media #{$mq-min-lg} {
    box-sizing: 0 5px $gray;
  }
}
