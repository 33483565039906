// -----------------------------------------------------------
// breakpoint
// -----------------------------------------------------------

$mq-md: "(max-width: 599px)";   // tablet - vertical
$mq-lg: "(max-width: 991px)";   // tablet - horizontal
$mq-xl: "(max-width: 1199px)";  // PC
$mq-min-md: "(min-width: 600px)";   // tablet - vertical
$mq-min-lg: "(min-width: 992px)";   // tablet  - horizontal
$mq-min-xl: "(min-width: 1200px)";  // PC

// -----------------------------------------------------------
// color
// -----------------------------------------------------------

$whiteGray: #f4f4f4;
$lightGray: #E4E4E4;
$gray: #A9A9A9;
$dimGray: #5A5A5A;
$darkGray: #3C3B3B;
$lightOrange: #FFEBD4;
$orange: #FF6627;
$green: #1DBA52;

// -----------------------------------------------------------
// font
// -----------------------------------------------------------

$jpFamily: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
$enFamily: 'Cabin', sans-serif;

// -----------------------------------------------------------
// width
// -----------------------------------------------------------

$baseWidth: 1000px;