@import "_variable";

// -----------------------------------------------------------
// header
// -----------------------------------------------------------

.ly-header {
  padding: 10px 5px 5px;
  background-color: #ffffff;

  &-wrap {
    margin: 0 auto;
    max-width: 1220px;
  }
}
.bl-header-pc {
  @media #{$mq-min-lg} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-item:nth-child(1) {
    flex-basis: calc(50% - 10px);
    max-width: 590px;
    @media #{$mq-lg} {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-item:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media #{$mq-min-md} {
    }
    @media #{$mq-min-lg} {
      flex-basis: calc(50% - 10px);
      justify-content: flex-end;
    }

    @media #{$mq-min-xl} {
      flex-basis: calc(42% - 10px);
    }
    .el-cta-rsv {
      @media #{$mq-md} {
        text-align: center;
      }
    }
    .el-cta-btn {
      @media #{$mq-min-md} {
        margin-left: 10px;
      }
    }
  }
}

// -----------------------------------------------------------
// footer
// -----------------------------------------------------------

.ly-footer {
  border-top: 2px solid $lightGray;

  &-wrap {
    margin: 0 auto;
    padding: 20px;
    max-width: 1120px;

    @media #{$mq-min-lg} {
      padding: 30px 0;
    }
  }
}

.bl-footer {
  color: $dimGray;
  text-align: center;

  @media #{$mq-min-lg} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
  }

  ul {
    margin: 10px 0 0;
    padding: 0;
    list-style-type: none;

    @media #{$mq-min-lg} {
      margin-top: 0;
    }

    li {
      display: inline-block;
      padding: 0 10px;

      @media #{$mq-min-md} {
        padding: 0;
      }

      &:before {
        @media #{$mq-min-md} {
          display: inline-block;
          margin: 0 1em;
          color: $lightGray;
          content: "|";
        }
      }
    }

    li:last-child:after {
      @media #{$mq-min-md} {
        display: inline-block;
        margin: 0 1em;
        color: $lightGray;
        content: "|";
      }
    }

    a {
      color: $dimGray;
      text-decoration: none;

      &:hover,
      &:active {
        opacity: 0.7;
      }
    }
  }
}

.bl-copylight {
  padding: 10px 0;
  background-color: $orange;
  color: #ffffff;
  text-align: center;
  font-size: 13px;
}

// -----------------------------------------------------------
// cta
// -----------------------------------------------------------

.bl-cta {
  margin: 0 auto;
  padding: 30px 0;
  max-width: 1125px;

  @media #{$mq-min-lg} {
    padding: 60px 0;
  }
}

.el-cta {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}
.el-cta-sp {
  @media #{$mq-md} {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 40px;
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.bl-cta-body-pc {
  display: none;
  @media #{$mq-min-lg} {
    display: block;
  }
}

.bl-cta-body-sp {
  display: block;
  @media #{$mq-min-lg} {
    display: none !important;
  }
}

.bl-cta-body {
  @media #{$mq-min-lg} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-item + .bl-cta-body-item {
    text-align: center;
  }

  &-item:nth-child(1) {
    position: relative;
    flex-basis: 44%;
    text-align: center;

    @media #{$mq-min-lg} {
      text-align: left;
    }

    @media #{$mq-min-xl} {
      &:after {
        position: absolute;
        top: 50%;
        right: -12px;
        display: inline-block;
        width: 65px;
        height: 65px;
        background: url("../../images/icon/icon-arrow.svg") no-repeat top 50%
          left 50%;
        background-size: contain;
        content: "";
        transform: translateY(-50%);
      }
    }
  }

  // 電話番号
  &-item:nth-child(2) {
    @media #{$mq-min-lg} {
      flex-basis: 29%;
    }
  }

  // ボタン
  &-item:nth-child(3) {
    @media #{$mq-min-lg} {
      flex-basis: 20%;
    }
  }
}

.bl-cta-txt {
  color: $orange;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.3;

  @media #{$mq-min-md} {
    font-size: 33px;
  }

  span {
    display: block;
  }

  &-txtSM {
    color: #000000;
    font-size: 50%;
  }

  &-txtMD {
    color: #000000;
    font-size: 72.27%;
  }

  &-txtAnnot {
    margin-top: 10px;
    font-size: 42.42%;
    font-weight: normal;
    color: #5a5a5a;
  }
}

// cta用ボタン
.bl-cta-btn {
  text-align: center;
}

// -----------------------------------------------------------
// リード
// -----------------------------------------------------------

.bl-lead {
  position: relative;
  overflow: hidden;
  padding: 40px 20px 20vw 20px;
  background-color: $lightOrange;

  @media #{$mq-min-lg} {
    position: relative;
    overflow: hidden;
    padding: 60px 0 calc(10vw + 50px) 0;
  }

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 20vw solid #ffffff;
    border-left: 100vw solid transparent;
    content: "";

    @media #{$mq-min-lg} {
      border-bottom: 10vw solid #ffffff;
    }
  }

  &:after {
    position: absolute;
    right: 0;
    bottom: 0;
    border-right: 100vw solid transparent;
    border-bottom: 20vw solid #ffffff;
    content: "";

    @media #{$mq-min-lg} {
      border-bottom: 10vw solid #ffffff;
    }
  }

  &-ttl {
    box-sizing: border-box;
    margin: 0 auto;
    padding-bottom: 20px;
    max-width: $baseWidth;
    font-weight: 600;
    font-size: 22px;

    @media #{$mq-lg} {
      text-align: center;
    }

    @media #{$mq-min-md} {
      font-size: 33px;
    }

    @media #{$mq-min-lg} {
      padding: 0 0 35px 353px;
      background: url("../../images/top/bg-lead@2x.png") no-repeat bottom 0 left
        0;
      background-size: contain;
    }

    br {
      display: none;

      @media #{$mq-min-md} {
        display: inline;
      }
    }

    span {
      color: $orange;
      text-emphasis: filled #000000;
    }
  }
}

.bl-lead-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  max-width: $baseWidth;

  @media #{$mq-min-md} {
    justify-content: space-between;
  }

  &-item {
    flex-basis: calc(50% - 10px);
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 20px 10px;
    border-radius: 12px;
    background-color: #ffffff;
    text-align: center;

    @media #{$mq-min-md} {
      flex-basis: calc(33.33% - 20px);
      padding: 30px 0;
      font-size: 23px;
    }

    br {
      display: none;

      @media #{$mq-min-md} {
        display: inline;
      }
    }
  }

  &-img {
    display: inline-block;
    width: 52px;
  }

  p {
    margin: 10px 0 0;
    color: $dimGray;

    @media #{$mq-min-md} {
      font-size: 23px;
    }
  }
}

// -----------------------------------------------------------
// リード下cta
// -----------------------------------------------------------

.bl-cta2 {
  margin: 0 auto 40px auto;
  max-width: 1060px;
  text-align: center;

  @media #{$mq-min-md} {
    margin: 0 auto 80px auto;
    background: url("../../images/top/bg-cta@2x.png") no-repeat top 50% left 50%;
    background-size: contain;
  }

  &-ttl {
    color: $orange;
    font-weight: 600;
    font-size: 22px;

    @media #{$mq-min-md} {
      margin-bottom: 20px;
    }

    @media #{$mq-min-lg} {
      font-size: 38px;
    }
  }

  &-tel {
    @media #{$mq-min-md} {
      margin-bottom: 20px;
    }

    @media #{$mq-min-lg} {
      font-size: 28px;
    }
  }

  &-btn {
    @media #{$mq-min-md} {
      .el-cta-btn {
        font-size: 18px;
      }
    }
  }
}

// -----------------------------------------------------------
// 選ばれる理由
// -----------------------------------------------------------

.bl-reason {
  padding: 40px 20px;
  background-color: $lightOrange;
  text-align: center;

  @media #{$mq-min-lg} {
    padding-top: 80px;
  }

  &-ttl {
    margin-bottom: 20px;

    @media #{$mq-min-md} {
      margin-bottom: 40px;
    }
  }
}

.bl-reason-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  max-width: $baseWidth;

  @media #{$mq-min-md} {
    justify-content: space-between;
  }

  &-item {
    flex-basis: calc(50% - 10px);
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 12px;
    background-color: #ffffff;

    @media #{$mq-min-md} {
      flex-basis: calc(33.33% - 20px);
    }

    @media #{$mq-min-lg} {
      padding: 30px;
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    background-color: $orange;

    @media #{$mq-min-lg} {
      width: 104px;
      height: 104px;
    }

    img {
      width: 40px;

      @media #{$mq-min-lg} {
        width: 60px;
      }
    }
  }

  p {
    margin-bottom: 0;
    color: $dimGray;

    @media #{$mq-min-md} {
      font-size: 22px;
    }
  }
}

// 年間相談件数
.bl-reason-data {
  margin: 0 auto;
  padding-top: 40px;
  max-width: 1000px;

  @media #{$mq-min-md} {
    display: grid;
    align-items: center;
    justify-content: center;

    gap: 10px;
    grid-template-columns: 310px 240px;
  }

  @media #{$mq-min-lg} {
    align-items: start;
    padding-top: 60px;
    grid-template-columns: 500px 240px;
  }

  &-item {
    margin-bottom: 30px;

    @media #{$mq-min-md} {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
      color: $dimGray;

      @media #{$mq-min-md} {
        text-align: left;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(1) {
      @media #{$mq-min-lg} {
        padding: 20px 0 20px 175px;
        background: url("../../images/top/img-family@2x.png") no-repeat top 50%
          left 0;
        background-size: contain;
      }
    }
  }

  &-ttl {
    font-size: 18px;

    @media #{$mq-min-lg} {
      font-size: 25px;
    }
  }

  &-num {
    display: inline-block;
    border-bottom: 4px solid #ffcb27;
    color: $orange;
    font-size: 50px;
    font-family: $enFamily;
    line-height: 1.3;

    @media #{$mq-min-lg} {
      font-size: 60px;
    }

    span {
      font-size: 46.66%;
    }
  }
}

.bl-reason-per {
  &-item {
    p {
      margin: 0;
    }
  }
}

// -----------------------------------------------------------
// お客様の声
// -----------------------------------------------------------

.bl-voice {
  padding: 40px 20px;
  background-color: $lightOrange;

  @media #{$mq-min-lg} {
    padding: 40px 0 80px 0;
  }

  &-ttl {
    margin-bottom: 20px;
    text-align: center;

    @media #{$mq-min-md} {
      margin-bottom: 40px;
    }
  }
}

.bl-voice-body {
  margin: 0 auto;
  max-width: $baseWidth;

  @media #{$mq-min-md} {
    display: flex;
    justify-content: space-between;
  }

  &-item {
    margin-bottom: 20px;

    @media #{$mq-min-md} {
      flex-basis: 47.8%;
    }

    p {
      margin-bottom: 0;
      color: $dimGray;
    }
  }
}

.bl-baloon {
  display: flex;

  &-item {
    &--face {
      flex-basis: calc(20% - 5px);

      p {
        margin: 0 5px;
      }
    }

    &--txt {
      position: relative;
      flex-basis: calc(80% - 5px);
      margin-left: 10px;
      padding: 20px;
      border-radius: 12px;
      background-color: #ffffff;

      &:before {
        position: absolute;
        top: 33px;
        left: -6px;
        display: block;
        width: 14px;
        height: 14px;
        background-color: #ffffff;
        content: "";
        transform: rotate(45deg);
      }
    }
  }

  &-ttl {
    color: $orange;
    font-weight: 600;
    font-size: 135.71%;
  }
}

.bl-star {
  position: relative;
  overflow: hidden;
  line-height: 1;

  dt {
    position: absolute;
    top: 50%;
    display: inline-block;
    font-size: 10px;
    transform: translateY(-50%);
  }

  dd {
    display: inline-block;
    margin-left: 3em;
  }

  &-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    background: url("../../images/icon/icon-star.svg") no-repeat top 50% left
      50%;
    background-size: contain;

    &--half {
      background: url("../../images/icon/icon-halfstar.svg") no-repeat top 50%
        left 50%;
      background-size: contain;
    }
  }
}

// -----------------------------------------------------------
// ご相談の流れ
// -----------------------------------------------------------

.bl-flow {
  padding: 40px 20px;
  background-color: $whiteGray;

  @media #{$mq-min-lg} {
    padding: 80px 0;
  }

  &-ttl {
    text-align: center;
  }

  &-subttl {
    margin: 20px 0 0;
    font-weight: normal;
    font-size: 71.05%;
  }

  &-line-ttl {
    position: relative;
    margin: 30px auto 0 auto;
    max-width: $baseWidth;
    text-align: center;

    @media #{$mq-min-lg} {
      margin-top: 40px;
    }

    span {
      position: relative;
      z-index: 1;
      display: inline-block;
      padding: 0 1em;
      background-color: $whiteGray;
      color: $orange;
      font-weight: 600;
      font-size: 18px;

      @media #{$mq-min-lg} {
        font-size: 27px;
      }
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-block;
      width: 100%;
      border-top: 2px solid $orange;
      content: "";
      transform: translateX(-50% 50%);
    }
  }
}

.bl-flow-unit {
  margin: 20px auto 0 auto;
  max-width: $baseWidth;

  @media #{$mq-min-lg} {
    display: flex;
    overflow: hidden;
    margin-top: 40px;
  }

  &-item {
    position: relative;
    padding: 20px 20px 10px;
    background-color: $lightOrange;
    text-align: center;

    @media #{$mq-min-lg} {
      flex-basis: 25%;
      padding: 40px 0 40px 20px;
      min-height: 160px;
    }

    &:after {
      position: absolute;
      bottom: -20px;
      left: 0;
      z-index: 1;
      border-width: 20px calc(50vw - 20px) 0 calc(50vw - 20px);
      border-style: solid;
      border-color: $lightOrange transparent transparent transparent;
      content: "";

      @media #{$mq-min-lg} {
        bottom: 50%;
        left: calc(100% - 12px);
        z-index: 10;
        border-width: 200px 0 200px 30px;
        border-color: transparent transparent transparent $lightOrange;
        transform: translateY(50%);
      }
    }

    &:nth-child(even) {
      background-color: #ffffff;

      &:after {
        border-color: #ffffff transparent transparent transparent;

        @media #{$mq-min-lg} {
          border-color: transparent transparent transparent #ffffff;
        }
      }
    }

    &:nth-child(n + 2) {
      padding-top: 40px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &-num {
    color: $orange;
    font-weight: 700;
    font-size: 28px;
    font-family: $enFamily;
    line-height: 1;
  }

  &-txt {
    margin: 10px 0 0;
    color: $dimGray;

    @media #{$mq-min-lg} {
      font-size: 18px;
    }
  }

  &-icon {
    margin: 10px auto 0 auto;
    width: 50px;
    height: 50px;

    @media #{$mq-min-lg} {
      position: absolute;
      left: calc(50% + 10px);
      transform: translateX(-50%);
    }

    &--1 {
      @media #{$mq-min-lg} {
        bottom: 34px;
      }
    }

    &--2 {
      @media #{$mq-min-lg} {
        bottom: 43px;
      }
    }

    &--3 {
      @media #{$mq-min-lg} {
        bottom: 38px;
      }
    }

    &--4 {
      width: 70px;
      height: 70px;

      @media #{$mq-min-lg} {
        bottom: 25px;
      }
    }
  }
}

.bl-flow-baloon {
  margin: 20px auto 0;
  max-width: $baseWidth;

  @media #{$mq-min-md} {
    display: flex;
    align-items: start;
    justify-content: space-between;
  }

  @media #{$mq-min-lg} {
    margin-top: 40px;
  }

  &-item img {
    @media #{$mq-md} {
      width: 150px;
    }
  }

  &-item + .bl-flow-baloon-item {
    margin-top: 20px;
  }

  &-item--txt {
    position: relative;
    padding: 20px;
    border-radius: 12px;
    background-color: #ffffff;

    @media #{$mq-min-lg} {
      padding: 30px 60px;
      font-size: 19px;
    }

    span {
      color: $orange;
      font-weight: 600;
      font-size: 142.1%;
    }

    p {
      margin: 10px 0 0;
      color: $dimGray;
    }

    &:before {
      position: absolute;
      right: 104px;
      bottom: -10px;
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #ffffff;
      content: "";
      transform: rotate(45deg);

      @media #{$mq-min-md} {
        right: -10px;
        bottom: calc(100% - 57px);
      }
    }
  }

  &-item--img {
    text-align: right;
  }
}

// -----------------------------------------------------------
// FAQ
// -----------------------------------------------------------

.bl-faq {
  padding: 40px 20px;
  background-color: $whiteGray;

  @media #{$mq-min-lg} {
    padding: 80px 0;
  }

  &-ttl {
    text-align: center;
  }

  &-subttl {
    margin-bottom: 20px;
    max-width: $baseWidth;
    font-size: 18px;

    @media #{$mq-min-md} {
      font-size: 27px;
    }

    &-icon {
      display: inline-block;
      margin-right: 5px;
      width: 0.8em;
      height: 0.8em;
      background-color: $orange;
    }
  }

  &-wrap {
    margin: 20px auto;
    max-width: $baseWidth;

    @media #{$mq-min-lg} {
      margin: 40px auto;
    }
  }

  .bl-faq-mt {
    margin-top: 60px;

    @media #{$mq-min-lg} {
      margin-top: 80px;
    }
  }
}

// -----------------------------------------------------------
// セルフチェック
// -----------------------------------------------------------

.bl-check {
  padding: 40px 20px;
  background-color: $lightOrange;

  @media #{$mq-min-lg} {
    padding: 80px 0;
  }

  &-ttl {
    text-align: center;
  }

  &-subttl {
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 68.42%;
    line-height: 1;

    @media #{$mq-min-lg} {
      margin-bottom: 20px;
    }
  }

  &-body {
    margin: 20px auto 0;
    max-width: $baseWidth;

    @media #{$mq-min-lg} {
      margin-top: 60px;
    }

    & + .bl-check-body {
      margin-top: 40px;

      @media #{$mq-min-lg} {
        margin-top: 60px;
      }
    }
  }

  &-q {
    position: relative;
    padding-left: 40px;
    font-size: 16px;

    @media #{$mq-min-md} {
      padding-left: 60px;
      font-size: 28px;
    }

    &:before {
      position: absolute;
      top: -2px;
      left: 0;
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 100px;
      background-color: $orange;
      color: #ffffff;
      content: "Q";
      text-align: center;
      font-size: 20px;
      font-family: $enFamily;
      line-height: 30px;

      @media #{$mq-min-md} {
        width: 46px;
        height: 46px;
        font-size: 30px;
        line-height: 46px;
      }
    }
  }

  &-a {
    margin-top: 20px;
    color: $dimGray;

    @media #{$mq-min-md} {
      margin-top: 25px;
      font-size: 25px;
    }
  }
}

.bl-check-group {
  display: flex;
  justify-content: space-between;

  &-2col {
    flex-basis: calc(50% - 5px);

    @media #{$mq-min-lg} {
      flex-basis: calc(50% - 20px);
    }
  }

  label {
    display: inline-block;

    &:hover {
      cursor: pointer;
    }
  }
}

.bl-radio {
  text-align: center;

  label {
    display: block;
    box-sizing: border-box;
    padding: 10px 20px;
    width: 100%;

    @media #{$mq-min-lg} {
      padding: 20px 30px;
    }
  }

  input {
    display: none;
  }

  input[type="radio"]:checked + label {
    position: relative;

    // 選択時の挙動（オレンジのアウトライン）
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      border: 3px solid $orange;
      border-radius: 12px;
      content: "";
    }
  }
}

.bl-checkbox {
  position: relative;
  display: block;
  padding: 10px 20px;

  @media #{$mq-min-md} {
    padding: 20px 30px;
  }

  & + .bl-checkbox {
    margin-top: 20px;
  }

  span {
    display: inline-block;
    padding-left: 30px;

    @media #{$mq-min-md} {
      padding-left: 50px;
    }
  }

  input {
    display: none;
  }

  input[type="checkbox"] + span:before {
    position: absolute;
    top: 12px;
    left: 20px;
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    border: 1px solid #000000;
    content: "";

    @media #{$mq-min-md} {
      top: 24px;
      left: 30px;
    }
  }

  input[type="checkbox"]:checked + span:before {
    background: url("../../images/icon/icon-check.svg") no-repeat top 50% left
      50%;
    background-size: 80%;
  }
}
